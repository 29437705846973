import Vue from 'vue';

const viewableImpressionMixin = refKey => {
	return {
		data() {
			return {
				observer: null,
				isVisible: false,
				startTime: null,
				timeoutId: null,
			};
		},
		methods: {
			initIntersectionObserver() {
				if (!refKey) {
					console.error('refKey is required');
					return;
				}
				if (!this.$refs[refKey]) {
					console.error(`this.$refs.${refKey} is not found`);
					return;
				}
				const options = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5,
				};

				const observer = new IntersectionObserver(entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							if (entry.intersectionRatio >= 0.5 && !this.isVisible) {
								// 광고의 절반 이상이 화면에 노출될 때 시작 시간 기록
								this.startTime = Date.now();
								this.isVisible = true;
								// 1초 후에 광고가 1초 이상 노출되었을 경우 Viewable Impression을 트리거
								this.timeoutId = setTimeout(() => {
									if (this.isVisible && Date.now() - this.startTime >= 1000) {
										this.triggerGTMViewableImpression();
									}
								}, 1000);
							}
						} else {
							// 광고가 화면에서 사라질 때 초기화
							this.isVisible = false;
							this.clearTimeout();
						}
					});
				}, options);

				// vue component에 ref를 건 경우
				if (this.$refs[refKey] instanceof Vue) {
					observer.observe(this.$refs[refKey].$el);
					// 일반 html element에 ref를 건 경우
				} else if (this.$refs[refKey] instanceof Element) {
					observer.observe(this.$refs[refKey]);
				}
				// 나중에 해제하기 위해 등록
				this.observer = observer;
			},
			destroyIntersectionObserver() {
				if (this.observer) {
					this.observer.disconnect();
					this.observer = null;
				}
			},
			// 이 method를 컴포넌트에서 override해서 사용.
			triggerGTMViewableImpression() {
				console.warn('impressionMixin의 triggerGTMViewableImpression을 override해주세요.');
			},
			clearTimeout() {
				if (this.timeoutId) {
					clearTimeout(this.timeoutId);
					this.timeoutId = null;
				}
			},
		},
		mounted() {
			this.initIntersectionObserver();
		},
		beforeDestroy() {
			this.destroyIntersectionObserver();
			this.clearTimeout();
		},
	};
};

export default viewableImpressionMixin;
