
import { mapGetters } from 'vuex';

import { GET_USER_META_INTEREST_DEPARTMENT, mapGettersOfUserMeta } from '@/store/userMeta';

import InterestJobsDrawer from '@/components/case/list/main/molecules/InterestJobsDrawer.vue';
import InterestJobsModal from '@/components/case/list/main/molecules/InterestJobsModal.vue';
import CaseListFilterContainer from '@/components/case/list/main/organisms/CaseListFilterContainer.vue';
import HorizontalDragScroll from '@/components/common/atoms/HorizontalDragScroll.vue';

import layoutMixin from '@/mixins/layoutMixin';

import jobTaxonomies from '@/static/data/job_taxonomies.json';

export default {
	name: 'InterestJobsChanger',
	mixins: [layoutMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			categoryMap: jobTaxonomies,
			currentJobs: [],
		};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfUserMeta([GET_USER_META_INTEREST_DEPARTMENT]),
		open: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit('update:show', value);
			},
		},
		defaultJobs() {
			return this[GET_USER_META_INTEREST_DEPARTMENT];
		},
	},
	watch: {
		defaultJobs: {
			handler(newValue) {
				this.currentJobs = newValue;
			},
			immediate: true,
		},
	},
	async mounted() {
		const shouldChangeInterestJob = await this.checkShouldChangeInterestJob();
		this.$emit('update:show', shouldChangeInterestJob);
		this.currentJobs = shouldChangeInterestJob ? [] : this.defaultJobs;
	},
	methods: {
		async checkShouldChangeInterestJob() {
			if (!this.isUser) {
				return false;
			}

			const { data: checkShouldChangeInterestJob } = await this.$api.userMeta.checkShouldChangeInterestJob();
			return checkShouldChangeInterestJob;
		},
		openDialog() {
			this.open = true;
		},
	},
	components: {
		InterestJobsDrawer,
		InterestJobsModal,
		CaseListFilterContainer,
		HorizontalDragScroll,
	},
};
