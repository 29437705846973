import { mapGetters } from 'vuex';

import {
	GET_IS_FINISHED_UPDATE_PROFILE,
	LOAD_CHECK_UPDATE_PROFILE,
	SET_IS_SHOW_USER_META_MODAL,
	mapActionsOfUserMeta,
	mapGettersOfUserMeta,
	mapMutationsOfUserMeta,
} from '@/store/userMeta';

const userMetaModalMixin = {
	computed: {
		...mapGetters(['isUser', 'userNo']),
		...mapGettersOfUserMeta([GET_IS_FINISHED_UPDATE_PROFILE]),
	},
	watch: {
		$route(to, from) {
			// router 변경이 있을 경우 감지함
			if (this.isUser && to !== from) {
				this.$_handleShowUpdateProfile();
			}
		},
		[GET_IS_FINISHED_UPDATE_PROFILE]() {
			this.$_handleShowUpdateProfile();
		},
	},
	async mounted() {
		if (this.isUser) {
			await this.$_handleCheckUpdateProfile();
		}
	},
	methods: {
		...mapMutationsOfUserMeta([SET_IS_SHOW_USER_META_MODAL]),
		...mapActionsOfUserMeta([LOAD_CHECK_UPDATE_PROFILE]),
		/*
		 * updateProfile 모달 띄우기
		 * ~닉네임까지 완료하지 않았거나, 대학/전공이 없을 때
		 */
		$_handleShowUpdateProfile() {
			try {
				if (!this[GET_IS_FINISHED_UPDATE_PROFILE] || this.userNo === 1178183) {
					if (this.$_app_canOpenModal) {
						return this.$_app_openModal({
							component: 'AppUserMetaModal',
							isDismissible: false,
						});
					}
					window.location.href = '/profile/signup';
				}
			} catch (error) {
				console.error(error);
				this.$customLogger(
					'$_handleShowUpdateProfile error',
					{
						userNo: this.userNo,
						error,
					},
					'error',
				);
			}
		},
		/**
		 * @param {string} infoParameterName
		 * 에러가 나면 return { "message": "no auth"}
		 * 그렇지 않으면 예시 return { "content": "univ": "서울대학교", "major": "컴퓨터공학과" }
		 * @returns {Promise<{content: {univ: string, major: string}}|{message: string}>}
		 * @private
		 */
		async $_getUserMetaInfo(infoParameterName) {
			try {
				const params = {
					info_parameter_name: infoParameterName,
				};
				return await this.$api.userMeta.getUserMetaInfo(params);
			} catch (error) {
				console.error('$_getUserMetaInfo error : ', error);
				return {};
			}
		},
		$_handleCheckUpdateProfile() {
			// updateProfile 완료 여부 체크
			this[LOAD_CHECK_UPDATE_PROFILE]();
		},
	},
};

export default userMetaModalMixin;
