
import { highlight } from '@/utils/highlight';
import { debounce, jsonToArray } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';
import visualViewportMixin from '@/mixins/visualViewportMixin';

export default {
	name: 'RecommendationFilterModal',
	mixins: [layoutMixin, visualViewportMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		defaultSelectedItems: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			keyword: '',
			loading: false,
			items: [],
			selectedItems: this.defaultSelectedItems,
			isEmpty: false,
			showDropdown: false,
		};
	},
	computed: {
		hasNoChange() {
			return (
				this.defaultSelectedItems.length === this.selectedItems.length &&
				this.defaultSelectedItems.every(item => this.selectedItems.includes(item))
			);
		},
		listPaddingBottom() {
			const ITEM_HEIGHT = 44;
			return Math.max(ITEM_HEIGHT * 2, ITEM_HEIGHT * 2 + this.differenceBetweenViewports);
		},
	},
	watch: {
		keyword(newValue) {
			this.searchItems(newValue);
		},
		defaultSelectedItems(newValue) {
			this.selectedItems = newValue;
		},
		async show(newValue) {
			if (!newValue) {
				return;
			}

			await this.$nextTick();
			const inputElement = this.$refs.searchInput?.$el?.querySelector('input[type="text"]');
			if (inputElement) {
				inputElement.focus();
			}
		},
	},
	methods: {
		closeModal() {
			this.keyword = '';
			this.selectedItems = this.defaultSelectedItems;
			this.items = [];
			this.$emit('close');
		},
		searchItems: debounce(async function (keyword) {
			this.isEmpty = false;
			this.items = [];
			if (this.keyword === '') {
				this.showDropdown = false;
				return;
			}

			try {
				this.loading = true;
				this.showDropdown = true;

				const autocompleteApi =
					this.type === '회사'
						? this.$api.autocomplete.getAutocompleteCompany
						: this.$api.autocomplete.getAutocompleteJob;

				const { data } = await autocompleteApi({ keyword });
				this.items = jsonToArray(data);
				if (this.items.length === 0) {
					this.isEmpty = true;
				}
			} catch (error) {
				console.error(error);
				this.$emit('filter-error');
			} finally {
				this.loading = false;
			}
		}, 500),
		highlightedKeyword(word) {
			return highlight(this.keyword, word);
		},
		handleClickItem(item) {
			if (this.selectedItems.includes(item)) {
				this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item);
			} else {
				this.selectedItems = [item, ...this.selectedItems];
			}
			this.showDropdown = false;
		},
		applyFilter() {
			if (this.hasNoChange) {
				this.closeModal();
				return;
			}

			this.$emit('apply-filter', this.selectedItems);
			this.closeModal();
		},
	},
};
