import { render, staticRenderFns } from "./RecommendationFilter.vue?vue&type=template&id=1da407e4&scoped=true&"
import script from "./RecommendationFilter.vue?vue&type=script&lang=js&"
export * from "./RecommendationFilter.vue?vue&type=script&lang=js&"
import style0 from "./RecommendationFilter.vue?vue&type=style&index=0&id=1da407e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da407e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaseListFilterContainer: require('/app/components/case/list/main/organisms/CaseListFilterContainer.vue').default})
