
import {
	GET_SPINNER_OFFSET_Y,
	mapGettersOfPullToRefresh,
	mapMutationsOfPullToRefresh,
	SET_SPINNER_OFFSET_Y,
} from '@/store/pullToRefresh';

import PullToRefreshSpinner from '@/components/pull-to/PullToRefreshSpinner.vue';

import layoutMixin from '@/mixins/layoutMixin';

const TOP_DEFAULT_CONFIG = {
	pullText: '땡겨요~~~',
	triggerText: '새로고침 시작!',
	loadingText: '로딩 중~!',
	doneText: '완료!!!',
	triggerDistance: 100,
};

export default {
	name: 'PullToRefresh',
	mixins: [layoutMixin],
	data() {
		return {
			onTop: true,
			timeout: null,
		};
	},
	computed: {
		...mapGettersOfPullToRefresh([GET_SPINNER_OFFSET_Y]),
		isShowPullToRefresh() {
			return process.env.NODE_ENV === 'production' ? this.IS_APP : this.IS_MOBILE;
		},
	},
	created() {
		this.TOP_DEFAULT_CONFIG = TOP_DEFAULT_CONFIG;
	},
	mounted() {
		if (this.IS_MOBILE) {
			window.addEventListener('scroll', this.setOnTop);
			this.$refs.pullToRefresh.addEventListener('webkitTransitionEnd', this.fixSafariScrolling);
		}
	},
	beforeDestroy() {
		if (this.IS_MOBILE) {
			window.removeEventListener('scroll', this.setOnTop);
			this.$refs.pullToRefresh.removeEventListener('webkitTransitionEnd', this.fixSafariScrolling);
		}
	},
	methods: {
		...mapMutationsOfPullToRefresh([SET_SPINNER_OFFSET_Y]),
		setOnTop() {
			this.onTop = (window.scrollY || window.pageYOffset) === 0;
		},
		getTranslateY(diff) {
			const spinnerHeight = 24;
			const paddingTop = 24;

			if (diff > 0) {
				this[SET_SPINNER_OFFSET_Y](diff);
			}
			return this[GET_SPINNER_OFFSET_Y] > 0
				? `transform: translateY(${-Math.floor(this[GET_SPINNER_OFFSET_Y]) + spinnerHeight + paddingTop}px)`
				: '';
		},
		fixSafariScrolling(event) {
			const childrenLength = event.target.children.length;
			const $scrollContainer = event.target.children[childrenLength - 1];
			$scrollContainer.style.overflowY = 'hidden';
			setTimeout(() => {
				$scrollContainer.style.overflowY = 'auto';
			});
		},
	},
	components: {
		PullToRefreshSpinner,
		PullTo: () => (process.client ? import('vue-pull-to') : Promise.resolve({ render: h => h('div') })),
	},
};
