
import { mapGetters } from 'vuex';

import { CASE_CATEGORY_GROUP_ID_MAP } from '@/plugins/constants';

import { isEmptyData } from '@/utils/utils';

import {
	mapGettersOfCase,
	CASE_CATEGORY,
	SET_CASE_LIST_INFO,
	SET_USER_PARTNER_INFO,
	mapMutationsOfCase,
	CASE_CATEGORY_GROUP_ID,
	SET_CASE_CATEGORY_GROUP_ID,
	SET_SORTING_OPTIONS,
	GET_SHOULD_RESET_CASE_LIST,
	SET_CASE_LIST,
	SET_INFINITE_ID,
	SET_IS_FETCHING_CASE_LIST,
	IS_JOB_SEEKING,
	SET_CASE_CATEGORY,
	RESET_CASE_LIST,
	mapActionsOfCase,
	LOAD_IS_BUY_MENTORING,
	LOAD_AFFILIATE_UNIV_EXPOSURE_DATA,
} from '@/store/case';
import { mapActionsOfEduList, RESET_KEYWORD } from '@/store/edu/list';
import { GET_ROUTE_NAME, mapGettersOfLoading } from '@/store/loading';
import { mapGettersOfUserVoice, GET_SHOW_USER_VOICE_ALERT } from '@/store/userVoice';

import CaseList from '@/components/case/list/CaseList.vue';
import AppLinkToast from '@/components/case/list/main/atoms/AppLinkToast.vue';
import CaseListSogangToast from '@/components/case/list/main/atoms/CaseListSogangToast.vue';
import CommunityTimeline from '@/components/case/timeline/CommunityTimeline.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userVoiceAlertMixin from '@/mixins/userVoiceAlertMixin';

export default {
	name: 'Index',
	mixins: [layoutMixin, caseMixin, appCommandMixin, userVoiceAlertMixin],
	async fetch() {
		if (this.isUser) {
			await Promise.all([await this.getUserPartnerInfo(), await this[LOAD_AFFILIATE_UNIV_EXPOSURE_DATA]()]);
		}
	},
	async asyncData({ store: { commit, dispatch }, route, redirect }) {
		try {
			const keyword = route.params.keyword;
			if (typeof keyword !== 'undefined') {
				redirect(`/search/index/${keyword}`);
				// 열람권 여부
				dispatch(`case/${LOAD_IS_BUY_MENTORING}`);
			} else {
				commit(`case/${SET_CASE_LIST_INFO}`, { keyword: null });
				await dispatch(`edu/list/${RESET_KEYWORD}`);
			}

			// if (store.getters[`case/${GET_SHOULD_RESET_CASE_LIST}`]) {
			// 	store.commit(`case/${SET_CASE_LIST}`, []);
			// 	store.commit(`case/${SET_CASE_LIST_INFO}`, {
			// 		count: 0,
			// 		page: 1,
			// 		questionTypeId: 0,
			// 	});
			// 	store.commit(`case/${SET_IS_FETCHING_CASE_LIST}`, true);
			// 	store.commit(`case/${SET_INFINITE_ID}`, +new Date());
			// }
		} catch (error) {
			console.error(error);
		}
	},
	computed: {
		...mapGettersOfCase([CASE_CATEGORY, CASE_CATEGORY_GROUP_ID, GET_SHOULD_RESET_CASE_LIST, IS_JOB_SEEKING]),
		...mapGetters(['isUser', 'userNo']),
		...mapGettersOfLoading([GET_ROUTE_NAME]),
		...mapGettersOfUserVoice([GET_SHOW_USER_VOICE_ALERT]),
		isLoaded() {
			return typeof this[CASE_CATEGORY_GROUP_ID] !== 'undefined';
		},
		isTimeline() {
			return this.$route.name === 'job-questions-keyword' && !this.$route.query.category;
		},
	},
	watch: {
		isTimeline() {
			if (this.isTimeline) {
				this[RESET_KEYWORD]();
			}
		},
		$route(to, from) {
			const hasCategory = !isEmptyData(to.query.category) && !isEmptyData(from.query.category);
			const isChangedCaseList = hasCategory && to.query.category !== from.query.category;
			const isChangedTimelineToCaseList =
				typeof from.query.category === 'undefined' && !isEmptyData(to.query.category);
			// 실험코드
			const isChancedCaseListToTimeline =
				!isEmptyData(from.query.category) && typeof to.query.category === 'undefined';
			if (isChangedCaseList || isChangedTimelineToCaseList || (isChancedCaseListToTimeline && !this.isOriginal)) {
				this[SET_CASE_LIST_INFO]({ questionTypeId: 0 });
				this[SET_CASE_LIST]([]);
				this[SET_CASE_LIST_INFO]({
					count: 0,
					page: 1,
				});
				this[SET_INFINITE_ID](+new Date());
				this[SET_IS_FETCHING_CASE_LIST](true);
			}
		},
		'$route.query.category': {
			handler(newValue) {
				const currentCategory = newValue;
				this[SET_CASE_CATEGORY](currentCategory);
				this[SET_CASE_CATEGORY_GROUP_ID](CASE_CATEGORY_GROUP_ID_MAP.get(currentCategory) || null);
				this[RESET_CASE_LIST]();
			},
			immediate: true,
		},
		shouldShowClassPtBanner() {
			this.setEduCharm();
		},
	},
	methods: {
		...mapMutationsOfCase([
			SET_USER_PARTNER_INFO,
			SET_CASE_CATEGORY_GROUP_ID,
			SET_SORTING_OPTIONS,
			SET_CASE_LIST_INFO,
			SET_CASE_CATEGORY,
		]),
		...mapActionsOfCase([RESET_CASE_LIST, LOAD_AFFILIATE_UNIV_EXPOSURE_DATA]),
		...mapActionsOfEduList([RESET_KEYWORD]),
		async getUserPartnerInfo() {
			try {
				const {
					data: {
						organization_type,
						is_affiliate,
						is_re_affiliated,
						is_user_using_affiliate,
						affiliated_before,
					},
				} = await this.$api.case.checkAffiliate();

				this[SET_USER_PARTNER_INFO]({
					organizationType: organization_type,
					isAffiliate: is_affiliate,
					isReAffiliated: is_re_affiliated,
					isUserUsingAffiliate: is_user_using_affiliate,
					isUserAffiliatedBefore: affiliated_before,
				});
			} catch (error) {
				console.error(error);
			}
		},
	},
	head() {
		const paramKeyword = this.$nuxt.$route.params.keyword;
		const keyword = typeof paramKeyword !== 'undefined' ? `${paramKeyword} ` : '';

		return {
			title: `${keyword} 커뮤니티 | 코멘토`,
			meta: [
				{
					hid: 'description',
					name: 'description',
					content: `${keyword}관련 현직자만 알고있는 취업정보를 읽고 올바르게 취업을 준비하세요.`,
				},
				{
					hid: 'og:description',
					name: 'og:description',
					content: `${keyword}관련 현직자만 알고있는 취업정보를 읽고 올바르게 취업을 준비하세요.`,
				},
				{
					hid: 'og:image',
					property: 'og:image',
					content: 'https://cdn.comento.kr/images/meta/meta-landing.jpg',
				},
				{
					hid: 'og:url',
					property: 'og:url',
					content: `https://comento.kr${this.$nuxt.$route.fullPath}`,
				},
				{
					hid: 'keywords',
					name: 'keywords',
					content: `현직자, 취업 질문, 꿀팁, 멘토링, 직무, 회사 분위기, 업무 내용, 스펙, 토익, ${keyword}`,
				},
			],
		};
	},
	meta: {
		isShowSubHeader: true,
		fluid: true,
		isFooterSlim: true,
	},
	middleware: 'check-update-profile',
	components: {
		CommunityTimeline,
		CaseList,
		CaseListSogangToast,
		AppLinkToast,
	},
};
