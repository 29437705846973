import layoutMixin from '@/mixins/layoutMixin';

export default {
	mixins: [layoutMixin],
	data() {
		return {
			originalViewportHeight: 0,
			differenceBetweenViewports: 0,
		};
	},
	mounted() {
		this.originalViewportHeight = window.visualViewport.height;
		window.visualViewport.addEventListener('resize', this.$_visualViewportMixin_handleVisualViewportResize);
	},
	beforeDestroy() {
		window.visualViewport.removeEventListener('resize', this.$_visualViewportMixin_handleVisualViewportResize);
	},
	methods: {
		$_visualViewportMixin_handleVisualViewportResize() {
			this.differenceBetweenViewports = this.originalViewportHeight - window.visualViewport.height;
			if (process.env.NODE_ENV !== 'production') {
				console.log('onVisualViewportResize', {
					originalViewportHeight: this.originalViewportHeight,
					differenceBetweenViewports: this.differenceBetweenViewports,
					currentViewportHeight: window.visualViewport.height,
				});
			}
		},
	},
};
