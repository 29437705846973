
import { defineComponent } from 'vue';

import { ESSAY_TUTORIAL_PATH } from '@/constants/paths';

import { cdnImageBaseUrl } from '@/plugins/constants';

import { moveToLaravelPage } from '@/utils/utils';

import AvatarWithBadge from '@/components/common/molecules/AvatarWithBadge.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default defineComponent({
	name: 'CaseListToAnalyticsNativeAdCard',
	mixins: [layoutMixin],
	computed: {
		avatarImage() {
			return `${cdnImageBaseUrl}/avatar/js_comenthor.png`;
		},
	},
	methods: {
		goToEssayTutorial() {
			moveToLaravelPage(ESSAY_TUTORIAL_PATH);
		},
	},
	components: { AvatarWithBadge },
});
