
export default {
	name: 'InterestJobsSelector',
	props: {
		categoryMap: {
			type: Object,
			required: true,
		},
		currentMidCategories: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			currentTopCategory: Object.keys(this.categoryMap)[0],
			swiperOptions: Object.freeze({
				direction: 'vertical',
				slidesPerView: 'auto',
				mousewheel: true,
				freeMode: true,
			}),
		};
	},
	methods: {
		getNumberOfSelectedCategories(topCategory) {
			return this.categoryMap[topCategory].filter(category => this.currentMidCategories.includes(category))
				.length;
		},
		toggleMidCategory(category) {
			this.$emit('toggle-mid-category', category);
		},
	},
};
