
import { INTEREST_DEPARTMENT } from '@/constants/user-meta';

import { SET_USER_META_INTEREST_DEPARTMENT, mapMutationsOfUserMeta } from '@/store/userMeta';

import InterestJobsChangerIndicator from '@/components/case/list/main/atoms/InterestJobsChangerIndicator.vue';
import InterestJobsSelector from '@/components/case/list/main/atoms/InterestJobsSelector.vue';

export default {
	name: 'InterestJobsModal',
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		categoryMap: {
			type: Object,
			required: true,
		},
		currentJobs: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			showErrorText: false,
			isSubmitting: false,
		};
	},
	methods: {
		...mapMutationsOfUserMeta([SET_USER_META_INTEREST_DEPARTMENT]),
		toggleMidCategory(category) {
			this.showErrorText = false;

			const hasCategory = this.currentJobs.includes(category);
			const isOverLimit = this.currentJobs.length >= 5;

			if (hasCategory) {
				const newMidCategories = this.currentJobs.filter(midCategory => midCategory !== category);
				this.$emit('update:currentJobs', newMidCategories);
				return;
			}

			if (isOverLimit) {
				this.showErrorText = true;
				return;
			}

			const newMidCategories = [...this.currentJobs, category];
			this.$emit('update:currentJobs', newMidCategories);
		},
		async handleComplete() {
			if (this.isSubmitting) {
				return;
			}

			try {
				this.isSubmitting = true;

				const { data } = await this.$api.userMeta.postUserMetaUpdateOrCreate({
					[INTEREST_DEPARTMENT]: this.currentJobs,
				});
				this[SET_USER_META_INTEREST_DEPARTMENT](data?.data?.content?.split(',') ?? []);

				this.$emit('update:show', false);
			} catch (error) {
				console.error(error);
			} finally {
				this.isSubmitting = false;
			}
		},
	},
	components: {
		InterestJobsChangerIndicator,
		InterestJobsSelector,
	},
};
