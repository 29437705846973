
import debounce from 'lodash/debounce';

import { CAMP_CHARM_ITEM, mapGettersOfCamp } from '@/store/camp';
import { CASE_LIST, mapGettersOfCase } from '@/store/case';
import { GET_USER_META_INTEREST_DEPARTMENT, mapGettersOfUserMeta } from '@/store/userMeta';

import CaseListEduCampBanner from '@/components/case/list/main/molecules/CaseListEduCampBanner.vue';
import AItestNativeAdCard from '@/components/case/timeline/molecules/AItestNativeAdCard.vue';
import CommunityCard from '@/components/case/timeline/molecules/CommunityCard.vue';
import TimelineAdCard from '@/components/case/timeline/molecules/TimelineAdCard.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import advertisementExposeMixin from '@/mixins/advertisement/advertisementExposeMixin';
import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import layoutMixin from '@/mixins/layoutMixin';
import stateForAppMixin from '@/mixins/stateForAppMixin';

export default {
	name: 'TimelineRecommendationFeed',
	mixins: [appCommandMixin, caseMixin, stateForAppMixin, advertisementExposeMixin, layoutMixin],
	computed: {
		...mapGettersOfCase([CASE_LIST]),
		...mapGettersOfCamp([CAMP_CHARM_ITEM]),
		...mapGettersOfUserMeta([GET_USER_META_INTEREST_DEPARTMENT]),
		cardMarginClass() {
			return this.IS_MOBILE ? 'mb-12' : 'mb-20';
		},
		otherJobsIndex() {
			const jobFilters = this[GET_USER_META_INTEREST_DEPARTMENT];
			return this[CASE_LIST].findIndex(item => item.type === 'qa' && !jobFilters.includes(item.job));
		},
	},
	methods: {
		computedTo({ search_c, search_j, search_o, request_no }) {
			// app에서 readCase native를 지원하는 경우 nuxt-link를 사용하지 않는다.
			// native로 보여줄 것이기 때문.
			if (this.$_app_canOpenNativePage) {
				return '';
			} else {
				return this.$_handleMakeUrl(search_c, search_j, search_o, request_no);
			}
		},
		handleClickCard: debounce(function (item) {
			if (this.$_app_canOpenNativePage) {
				this.$_for_app_setCaseQuestion({
					title: item.other_inf,
					description: item.question,
					createdAt: item.time,
					categoryGroup: item.category_group,
					category: item.category,
					company: item.search_c,
					job: item.search_j,
				});
				this.$_goReadCase(item);
			}
			this.$emit('click-card');
		}, 100),
		openFilter() {
			this.$emit('open-filter');
		},
	},
	components: {
		AItestNativeAdCard,
		CaseListEduCampBanner,
		TimelineAdCard,
		CustomNuxtLink,
		CommunityCard,
	},
};
