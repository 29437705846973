import { render, staticRenderFns } from "./CaseListEID118Section.vue?vue&type=template&id=26ef7ecc&scoped=true&"
import script from "./CaseListEID118Section.vue?vue&type=script&lang=js&"
export * from "./CaseListEID118Section.vue?vue&type=script&lang=js&"
import style0 from "./CaseListEID118Section.vue?vue&type=style&index=0&id=26ef7ecc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ef7ecc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomNuxtLink: require('/app/components/CustomNuxtLink.vue').default})
