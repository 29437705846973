
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AvatarWithBadge',
	props: {
		titleImage: {
			type: String,
			required: true,
		},
	},
});
