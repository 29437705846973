
import InterestJobsChangerIndicator from '@/components/case/list/main/atoms/InterestJobsChangerIndicator.vue';
import InterestJobsSelector from '@/components/case/list/main/atoms/InterestJobsSelector.vue';
import InterestJobsModal from '@/components/case/list/main/molecules/InterestJobsModal.vue';

export default {
	name: 'InterestJobsDrawer',
	extends: InterestJobsModal,
	components: {
		InterestJobsChangerIndicator,
		InterestJobsSelector,
	},
};
