
export default {
	name: 'PullToRefreshSpinner',
	props: {
		percent: {
			type: Number,
			default: 0,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		finish: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		integerPercent() {
			return this.finish ? 100 : Math.floor(this.percent);
		},
	},
};
