
import { cdnBaseUrl } from '@/plugins/constants';
import dayjs, { getTimeInAsia } from '@/plugins/dayjs';
import { formatDate } from '@/plugins/filters/formatDate';

import { highlightSearchContent, addSubstringAndAddMoreText } from '@/utils/highlight';

import CommunityCardEventLog from '@/components/case/timeline/atoms/CommunityCardEventLog.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CommunityCard',
	mixins: [layoutMixin],
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		title: {
			type: [String, Function],
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		likes: {
			type: [Number, String],
			default: 0,
		},
		views: {
			type: [Number, String],
			default: 0,
		},
		comments: {
			type: [Number, String],
			default: 0,
		},
		bookmarks: {
			type: Number,
			default: 0,
		},
		date: {
			type: String,
			default: '',
		},
		showEvent: {
			type: Boolean,
			default: false,
		},
		thumbnail: {
			type: String,
			default: '',
		},
		showDate: {
			type: Boolean,
			default: false,
		},
		isSearch: {
			type: Boolean,
			default: false,
		},
		showViewCount: {
			type: Boolean,
			default: false,
		},
		isCorrespondenceCompany: {
			type: Boolean,
			default: false,
		},
		isTimeline: {
			type: Boolean,
			default: false,
		},
		keyword: {
			type: String,
			default: '',
		},
		showJob: {
			type: Boolean,
			default: false,
		},
		isBookmarked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		getCardBackgroundImage() {
			const path = this.thumbnail ?? `${cdnBaseUrl}/images/meta/meta-landing.jpg`;
			const width = this.IS_MOBILE ? 400 : 260;
			return `${path}?s=${width}`;
		},
		getContentWithEllipsis() {
			const maxLength = this.IS_MOBILE ? 50 : this.thumbnail ? 110 : 140;
			const textContent = this.$_removeHTMLTag(this.content);
			const content = textContent.replace(/&nbsp;/g, '').replace(/\n/g, '');
			const moreText = `...<span class="more-text">더보기</span>`;

			if (this.isSearch) {
				return highlightSearchContent(textContent, maxLength, this.keyword);
			}

			if (content.length > maxLength) {
				// 단어별로 자르고 길면 '...더보기' 붙이기
				return addSubstringAndAddMoreText(content, maxLength);
			}
			if (content.length === 0) {
				return moreText;
			}
			return content;
		},
		bookmarkCount() {
			if (this.isTimeline) {
				return Math.ceil(this.bookmarks || this.views / 10);
			}
			return this.bookmarks;
		},
		isRecent() {
			const today = getTimeInAsia();
			return dayjs(this.date).isSame(today, 'day');
		},
		isJobWiki() {
			if (!this.item?.type) {
				return false;
			}
			return this.item.type === 'jobWiki';
		},
	},
	components: { CommunityCardEventLog },
	filters: {
		formatDate,
	},
};
