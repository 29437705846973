import { mapMutationsOfStateForApp, SET_SELECTED_CASE_QUESTION } from '@/store/stateForApp';

const stateForAppMixin = {
	methods: {
		...mapMutationsOfStateForApp([SET_SELECTED_CASE_QUESTION]),
		/**
		 * APP_NATIVE_PAGE.READ_CASE를 위한 state
		 * @param {Object} caseQuestion
		 * @param {string?} caseQuestion.title: 질문 제목
		 * @param {string?} caseQuestion.description: 질문 내용
		 * @param {string?} caseQuestion.categoryGroup: 질문 카테고리 대분류
		 * @param {string?} caseQuestion.category: 질문 카테고리 소분류
		 * @param {string?} caseQuestion.company: 질문 회사
		 * @param {string?} caseQuestion.job: 질문 직무
		 * @param {string?} caseQuestion.createdAt: 질문 생성 날짜
		 */
		$_for_app_setCaseQuestion(caseQuestion) {
			this[SET_SELECTED_CASE_QUESTION](caseQuestion);
		},
	},
};

export default stateForAppMixin;
