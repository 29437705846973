
import { CASE_CATEGORY_GTM_MAP } from '@/plugins/constants';

import { CASE_CATEGORY_GROUP_ID, GET_MEMBERSHIP_TYPE, mapGettersOfCase } from '@/store/case';

import CaseListFilterContainer from '@/components/case/list/main/organisms/CaseListFilterContainer.vue';
import RecommendationFilterModal from '@/components/case/timeline/molecules/RecommendationFilterModal.vue';
import HorizontalDragScroll from '@/components/common/atoms/HorizontalDragScroll.vue';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

// 직무에 ,가 있는 경우가 있어서 |로 구분
const JOBS_DELIMETER = '|';

export default {
	name: 'RecommendationFilter',
	mixins: [layoutMixin, communityGtmTriggerMixin, userInfoMixin],
	props: {
		showFilterModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			jobChips: [],
		};
	},
	computed: {
		...mapGettersOfCase([GET_MEMBERSHIP_TYPE, CASE_CATEGORY_GROUP_ID]),
	},
	watch: {
		'$route.query.jobs': {
			handler(newValue) {
				if (!newValue) {
					this.jobChips = [];
					return;
				}
				this.jobChips = decodeURIComponent(newValue).split(JOBS_DELIMETER);
			},
			immediate: true,
		},
	},
	methods: {
		openModal() {
			this.$emit('update:showFilterModal', true);
			const category = CASE_CATEGORY_GTM_MAP[this[CASE_CATEGORY_GROUP_ID]] ?? 'Home';
			this.$_clickJobFilter({
				...this.$_userInfoForGtm,
				membership_type: this[GET_MEMBERSHIP_TYPE] ?? 'none',
				category,
			});
		},
		closeModal() {
			this.$emit('update:showFilterModal', false);
		},
		handleApplyFilter(items) {
			const newJobsQuery = items.length === 0 ? undefined : items.join(JOBS_DELIMETER);
			this.$router.replace({ query: { ...this.$route.query, jobs: newJobsQuery } });
			Toast.show({
				message: newJobsQuery ? '직무 필터가 적용되었습니다.' : '직무 필터가 해제되었습니다.',
				type: 'basic',
				position: 'top',
				timeout: 1500,
				showIcon: false,
			});
			this.$_selectJobFilter({
				...this.$_userInfoForGtm,
				membership_type: this[GET_MEMBERSHIP_TYPE] ?? 'none',
				job_filter: items,
			});
		},
		handleFilterError() {
			Toast.show({
				message: '오류가 발생했습니다. 다시 시도해주세요.',
				type: 'error',
				position: 'top',
				timeout: 1500,
				showIcon: false,
			});
		},
		removeJob(job) {
			this.jobChips = this.jobChips.filter(item => item !== job);
			this.handleApplyFilter(this.jobChips);
		},
	},
	components: {
		RecommendationFilterModal,
		HorizontalDragScroll,
		CaseListFilterContainer,
	},
};
