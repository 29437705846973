
import { INTEREST_DEPARTMENT } from '@/constants/user-meta';

import { isEmptyData } from '@/utils/utils';

import { SET_USER_META_STEP, SET_IS_SHOW_USER_META_MODAL, mapMutationsOfUserMeta } from '@/store/userMeta';

import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CaseListNoRelationContentBanner',
	mixins: [layoutMixin, appCommandMixin],
	data() {
		return {
			relationCompanyAndJob: [],
		};
	},
	created() {
		this.handleGetCompanyAndJob(INTEREST_DEPARTMENT);
	},
	methods: {
		...mapMutationsOfUserMeta([SET_USER_META_STEP, SET_IS_SHOW_USER_META_MODAL]),
		async getUserMetaInfo(infoParameterName) {
			try {
				const params = {
					info_parameter_name: infoParameterName,
				};
				const { data } = await this.$api.userMeta.getUserMetaInfo(params);
				return data;
			} catch (error) {
				console.error(error);
				return {};
			}
		},
		async handleGetCompanyAndJob(infoParameterName) {
			const { content } = await this.getUserMetaInfo(infoParameterName);

			if (!isEmptyData(content)) {
				this.relationCompanyAndJob = [...this.relationCompanyAndJob, ...(content || [])];
			}
		},
		openUpdateProfileCompanyModal() {
			if (this.$_app_canOpenModal) {
				return this.$_app_openModal({
					component: 'AppUserMetaModal',
					isDismissible: false,
					args: { step: 3 },
				});
			}
			this[SET_USER_META_STEP](3);
			this[SET_IS_SHOW_USER_META_MODAL](true);
		},
	},
};
