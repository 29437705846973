
export default {
	name: 'HorizontalDragScroll',
	data() {
		return {
			isDown: false,
			startX: 0,
			scrollLeft: 0,
			velocityX: 0,
			momentumID: null,
		};
	},
	mounted() {
		this.$refs.scrollContainer.style.cursor = 'grab';
	},
	methods: {
		startDragging(e) {
			this.isDown = true;
			this.$refs.scrollContainer.style.cursor = 'grabbing';
			this.startX = e.pageX - this.$refs.scrollContainer.offsetLeft;
			this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
			this.cancelMomentumTracking();
		},
		stopDragging() {
			this.isDown = false;
			this.$refs.scrollContainer.style.cursor = 'grab';
			this.beginMomentumTracking();
		},
		drag(e) {
			if (!this.isDown) {
				return;
			}
			e.preventDefault();
			const x = e.pageX - this.$refs.scrollContainer.offsetLeft;
			const walk = (x - this.startX) * 1;
			const prevScrollLeft = this.$refs.scrollContainer.scrollLeft;
			this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
			this.velocityX = this.$refs.scrollContainer.scrollLeft - prevScrollLeft;
		},
		cancelMomentumTracking() {
			cancelAnimationFrame(this.momentumID);
		},
		beginMomentumTracking() {
			this.cancelMomentumTracking();
			this.momentumID = requestAnimationFrame(this.momentumLoop);
		},
		momentumLoop() {
			this.$refs.scrollContainer.scrollLeft += this.velocityX;
			this.velocityX *= 0.9;
			if (Math.abs(this.velocityX) > 0.5) {
				this.momentumID = requestAnimationFrame(this.momentumLoop);
			}
		},
	},
};
