
import CaseProfileAvatar from '@/components/case/home/main/atoms/CaseProfileAvatar.vue';

import caseMixin from '@/mixins/caseMixin';

const EVENT_TEXT = {
	feedback: '답변을 남겼어요.',
	comment: '댓글을 남겼어요.',
	like: '글을 좋아합니다.',
	adopt: '답변을 채택했어요.',
	caseReply: '답변을 남겼어요.',
	caseAdditionalReply: '답변에 댓글을 남겼어요.',
	feedbackLike: '답변을 좋아합니다.', // laravel
	answerLike: '답변을 좋아합니다.', // nuxt
	request_write: '새 질문을 작성했어요.',
	write: '새 글을 작성했어요.',
};

export default {
	name: 'CommunityCardEventLog',
	mixins: [caseMixin],
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		eventType() {
			return this.item.event.type;
		},
		eventText() {
			return EVENT_TEXT[this.eventType];
		},
		nickname() {
			return this.item.event.nickname;
		},
	},
	components: { CaseProfileAvatar },
};
