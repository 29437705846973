
import { mapGetters } from 'vuex';

import { cdnBaseUrl } from '@/plugins/constants';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CaseListEID118Section',
	mixins: [layoutMixin],
	data() {
		return {
			cdnBaseUrl,
			serviceList: [
				{ url: '/request/question', click_to: 'visitRequest', text: '질문하기', type: 'contents' },
				{ url: '/request/essay', click_to: 'visitRequest-ce', text: '자소서 첨삭', type: 'essay' },
				{ url: '/request/interview', click_to: 'visitRequest-in', text: '면접 대비', type: 'interview' },
				{ url: '/analytics', click_to: 'onboardAItest', text: 'AI 자소서 분석기', type: 'ai' },
			],
			univInfoList: [
				{
					no: 787,
					univ: '경희대학교',
					contents: 139436,
					ai: 394,
					essay: 336,
					interview: 78,
				},
				{
					no: 799,
					univ: '성균관대학교',
					contents: 157517,
					ai: 1407,
					essay: 506,
					interview: 101,
				},
				{
					no: 888,
					univ: '고려대학교',
					contents: 88329,
					ai: 327,
					essay: 200,
					interview: 54,
				},
			],
		};
	},
	computed: {
		...mapGetters(['userInfo']),
		userTargetUnivInfo() {
			if (this.userInfo) {
				const { api_partner_no } = this.userInfo;
				return this.univInfoList.find(item => item.no === api_partner_no);
			} else {
				return false;
			}
		},
	},
	components: { CustomNuxtLink },
};
