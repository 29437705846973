
import { getLocalStorage, setLocalStorage } from '@/utils/utils';

import { GET_PASS_INFO, mapGettersOfMyPage } from '@/store/myPage';

const LOCAL_STORAGE_KEY = 'sogangBenefitToastCount';

export default {
	name: 'CaseListSogangToast',
	data() {
		return {
			showToast: false,
		};
	},
	computed: {
		...mapGettersOfMyPage([GET_PASS_INFO]),
	},
	mounted() {
		const passName = this[GET_PASS_INFO]?.pass_name;
		const toastCount = Number(getLocalStorage(LOCAL_STORAGE_KEY));
		if (passName !== '서강대학교' || toastCount > 1) {
			return;
		}

		this.showToast = true;
		setLocalStorage(LOCAL_STORAGE_KEY, toastCount + 1);
	},
	methods: {
		handleClick() {
			this.$router.push('/mypage/setting');
			// 토스트를 확인한 유저는 토스트가 뜨지 않게 한다.
			setLocalStorage(LOCAL_STORAGE_KEY, 2);
		},
	},
};
