
import { defineComponent } from 'vue';

import { ANALYTICS_PATH } from '@/constants/paths';

import { cdnImageBaseUrl, baseUrl } from '@/plugins/constants';

import AvatarWithBadge from '@/components/common/molecules/AvatarWithBadge.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default defineComponent({
	name: 'AItestNativeAdCard',
	mixins: [layoutMixin],
	computed: {
		avatarImage() {
			return `${cdnImageBaseUrl}/avatar/js_comenthor.png`;
		},
	},
	created() {
		this.ANALYTICS_PATH = ANALYTICS_PATH;
		this.baseUrl = baseUrl;
	},
	components: { CustomNuxtLink, AvatarWithBadge },
});
