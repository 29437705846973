
import debounce from 'lodash/debounce';

import { INSIGHT_PATH, LOUNGE_PATH } from '@/constants/paths';

import { INSIGHT, LOUNGE } from '@/plugins/constants';

import { getCommunityCategoryName } from '@/utils/community';

import { GET_POSTS, mapGettersOfCommunityTimeline } from '@/store/community/timeline';

import CommunityCard from '@/components/case/timeline/molecules/CommunityCard.vue';
import TimelineAdCard from '@/components/case/timeline/molecules/TimelineAdCard.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';
import CommunityCardSkeleton from '@/components/skeleton/CommunityCardSkeleton.vue';

import advertisementExposeMixin from '@/mixins/advertisement/advertisementExposeMixin';
import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import layoutMixin from '@/mixins/layoutMixin';
import stateForAppMixin from '@/mixins/stateForAppMixin';

export default {
	name: 'TimelineCardListSection',
	mixins: [appCommandMixin, layoutMixin, caseMixin, stateForAppMixin, advertisementExposeMixin],
	computed: {
		...mapGettersOfCommunityTimeline([GET_POSTS]),
	},
	methods: {
		getCaseBoardType({ board_type, category_group }) {
			if (board_type !== 'qa') {
				return board_type;
			}
			return getCommunityCategoryName(category_group);
		},
		getTimelineDetailPath({ board_type, s_title, search_c, search_j, search_o, request_no }) {
			switch (board_type) {
				case 'qa':
					return this.$_handleMakeUrl(search_c, search_j, search_o, request_no);
				case INSIGHT:
					return `${INSIGHT_PATH}/${s_title}`;
				case LOUNGE:
					return `${LOUNGE_PATH}/${s_title}`;
			}
		},
		handleClickCard: debounce(function (item) {
			if (this.$_app_canOpenNativePage) {
				this.$_for_app_setCaseQuestion({
					title: item.title,
					category: item.category,
					categoryGroup: item.category_group,
					company: item.search_c,
					job: item.search_j,
					description: item.content,
				});
				this.$_goReadCase(item);
			}
			this.$emit('click-card');
		}, 100),
		computedTo(item) {
			// app에서 readCase native를 지원하는 경우 nuxt-link를 사용하지 않는다.
			// native로 보여줄 것이기 때문.
			if (this.$_app_canOpenNativePage) {
				return '';
			} else {
				return this.getTimelineDetailPath(item);
			}
		},
	},
	components: {
		TimelineAdCard,
		CustomNuxtLink,
		CommunityCard,
		CommunityCardSkeleton,
	},
};
