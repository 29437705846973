
export default {
	name: 'InterestJobsChangerIndicator',
	props: {
		currentMidCategories: {
			type: Array,
			required: true,
		},
		showErrorText: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		removeCategory(category) {
			this.$emit('remove-category', category);
		},
	},
};
