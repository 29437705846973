
import { baseUrl } from '@/plugins/constants';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'CaseListLoginBanner',
	mixins: [routeMixin, layoutMixin],
	data() {
		return {
			baseUrl,
		};
	},
	methods: {
		goToLoginPage() {
			this.$_routeMixin_href(`${this.baseUrl}/logout`);
		},
	},
};
