
import dayjs from '@/plugins/dayjs';

import { getLocalStorage, setLocalStorage } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

const LOCAL_STORAGE_KEY = 'appLinkToast';

export default {
	name: 'AppLinkToast',
	mixins: [layoutMixin, routeMixin],
	data() {
		return {
			open: false,
		};
	},
	mounted() {
		if (!this.IS_MOBILE) {
			return;
		}

		this.openToastIfNotClosedIn24Hours();
	},
	methods: {
		openAppLink() {
			this.$_routeMixin_open_page(
				'https://comento-f2f9f.web.app/?utm_source=internal&utm_medium=tooltip&utm_campaign=app_conversion&utm_term=bridge&utm_content=240801',
			);
		},
		closeToast() {
			this.open = false;
			setLocalStorage(LOCAL_STORAGE_KEY, dayjs());
		},
		openToastIfNotClosedIn24Hours() {
			const now = dayjs();
			const userClosedTime = getLocalStorage(LOCAL_STORAGE_KEY);
			const diffHours = now.diff(userClosedTime, 'hour');
			if (!userClosedTime || diffHours >= 24) {
				this.open = true;
			}
		},
	},
};
