
import { CASE_LIST_INFO, mapGettersOfCase } from '@/store/case';

import advertisementExposeMixin from '@/mixins/advertisement/advertisementExposeMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';
import viewableImpressionMixin from '@/mixins/viewableImpressionMixin';

export default {
	name: 'TimelineAdCard',
	mixins: [
		layoutMixin,
		advertisementExposeMixin,
		communityGtmTriggerMixin,
		userInfoMixin,
		viewableImpressionMixin('timelineAdCard'),
	],
	props: {
		adInfo: {
			type: Object,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			observer: null,
			isVisible: false, // 광고가 노출되었는지 여부
			startTime: null, // 광고가 화면에 노출된 시간 저장
		};
	},
	computed: {
		...mapGettersOfCase([CASE_LIST_INFO]),
		getContentWithEllipsis() {
			return this.adInfo.answer + `...<span class="more-text">더보기</span>`;
		},
		getAdGtmInfo() {
			return {
				where: 'home',
				product_name: 'native_image',
				page_depth: this[CASE_LIST_INFO].page - 1,
				order: this.index,
				inventory_number: this.adInfo?.inventory_number,
				agency_name: this.adInfo?.agency_name,
				client_name: this.adInfo?.client_name,
				job_posting_id: this.adInfo?.job_posting_id,
			};
		},
	},
	mounted() {
		this.triggerGTMImpression();
	},
	methods: {
		triggerGTMImpression() {
			this.$_impression({
				...this.$_userInfoForGtm,
				...this.getAdGtmInfo,
			});
		},
		triggerGTMViewableImpression() {
			this.$_viewableImpression({
				...this.$_userInfoForGtm,
				...this.getAdGtmInfo,
			});
		},
		triggerGTMClickAdBanner() {
			this.$_clickAdBanner({ ...this.$_userInfoForGtm, ...this.getAdGtmInfo });
			this.$_advertisementGoToUrl(this.adInfo.url);
		},
	},
};
