
import { cdnBaseUrl } from '@/plugins/constants';

import caseMixin from '@/mixins/caseMixin';

export default {
	name: 'CaseProfileAvatar',
	mixins: [caseMixin],
	props: {
		userNo: {
			type: Number,
			default: -1,
		},
		userNickname: {
			type: String,
			default: '',
		},
		userType: {
			type: Number,
			default: -1,
		},
		userProfileImage: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: 'small',
		},
		isProfileType: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cdnBaseUrl,
			avatarSize: 'small',
		};
	},
	computed: {
		defaultNickname() {
			return `${this.userType === 1 ? '멘티' : '멘토'}${this.userNo}`;
		},
		avatarText() {
			const text = this.userNickname || this.defaultNickname;
			return text.substr(0, 1);
		},
		getUserProfileImageUrl() {
			const sizeParam = `?s=60x60`;
			const hasBaseUrl = this.userProfileImage.includes(cdnBaseUrl);
			if (hasBaseUrl) {
				return `${this.userProfileImage}${sizeParam}`;
			}
			return `${cdnBaseUrl}/images/user/profile/${this.userProfileImage}${sizeParam}`;
		},
	},
};
